import React, { Component, Fragment } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import UserService from "../services/user.service";
import "../assets/css/crops.css";
import MaterialTable from "material-table";
import tableIcons from "./icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "@material-ui/core";
import {
  faDownload,
  faHome,
  faUpload,
  faForward,
  faListAlt
} from "@fortawesome/free-solid-svg-icons";
import { Button, Box } from "@material-ui/core";
import "../assets/css/milk.css";
import Papa from "papaparse";
import userService from "../services/user.service";

import axios from "axios";
import SummaryModal from "./components/SummaryModelMilkcollection";

export default class FpoMilk extends Component {
  constructor(props) {
    super(props);
    const today = new Date();
    const formattedDate = today.toISOString().split("T")[0];
    console.log("today", formattedDate);

    this.state = {
      loading: false,
      data: [],
      totalAmount:"",
      filteredData: [],
      centerNames: [],
      farmerName: "",
      milkTypes: [],

      tempSelectedFarmer: "",
      tempSelectedCenter: "",
      tempSelectedMilkType: "",

      fromDate: formattedDate,
      toDate: formattedDate,

      file: null,
      fileName: "",

      pageNo: 1,
      pageSize: 10,
      totalPages: 0,

      isModalOpen: false,
      summaryData: null,

      bmcCentre: "",
    };
  }

  getPageNumbers = () => {
    const { pageNo, totalPages } = this.state;
    let startPage = Math.floor((pageNo - 1) / 5) * 5 + 1;
    let endPage = Math.min(startPage + 4, totalPages);
    return Array.from(
      { length: endPage - startPage + 1 },
      (_, index) => startPage + index
    );
  };



  
  fetchingData = async () => {
    this.setState({ loading: true });
    try {
      const {
        pageNo,
        pageSize,
        tempSelectedCenter,
        tempSelectedFarmer,
        tempSelectedMilkType,
        fromDate,
        toDate,
      
        formattedDate,

      } = this.state;
      const response = await userService.getCollectionData(
        pageNo,
        pageSize,
        tempSelectedCenter || "all",
        tempSelectedFarmer || "all",
        tempSelectedMilkType || "all",
        fromDate || formattedDate,
        toDate || formattedDate,
       
      );
      const collectionData = response.data.collection_data;
      const totalRecords = response.data.total_records;
      const totalAmount = response.data.total_amount_sum;

      const totalPagescalculated = Math.ceil(totalRecords / pageSize);

      // this.setState({
      //   loading: false,
      //   data: collectionData,
      //   filteredData: collectionData,
      //   totalPages: totalPagescalculated,
      // });

      if (pageNo > totalPagescalculated) {
        this.setState({
          pageNo: 1,
          totalPages: totalPagescalculated,
          filteredData: collectionData,
          data: collectionData,
          totalAmount:totalAmount,



          loading: false,
        });
      } else {
        this.setState({
          totalPages: totalPagescalculated,
          filteredData: collectionData,
          data: collectionData,
          loading: false,
          totalAmount:totalAmount
        });
      }
    } catch (error) {
      this.setState({ loading: false });
      console.error("Error in fetching dispatch data", error);
    }
  };

  componentDidMount() {
    this.fetchCenters();

    this.fetchMilkTypes();
    this.fetchingData(this.state.pageNo);
  }

  componentDidUpdate(prevProps, prevState) {
    const { fromDate, toDate, tempSelectedCenter, tempSelectedFarmer, tempSelectedMilkType, pageNo } = this.state;
  
    // Check if any of the filter values or page number has changed
    // This will prevent fetching if only other irrelevant state values have changed
    if (
      prevState.pageNo !== pageNo || 
      prevState.tempSelectedCenter !== tempSelectedCenter || 
      prevState.tempSelectedFarmer !== tempSelectedFarmer ||
      prevState.tempSelectedMilkType !== tempSelectedMilkType ||
      prevState.fromDate !== fromDate || 
      prevState.toDate !== toDate
    ) {
      this.fetchingData();
    }
  }
  

  fetchCenters = async () => {
    try {
      const res = await userService.getCenters();
      console.log("Getting centers:", res.data.collection_centres);

      if (res.data && res.data.collection_centres) {
        this.setState({ centerNames: res.data.collection_centres });

        const firstBmcName =
          res.data.collection_centres[0]?.bmcs?.[0]?.bmc_name ||
          "No BMC Available";
        this.setState({ bmcCentre: firstBmcName });
      }
    } catch (error) {
      console.error("Error in fetching centers:", error);
    }
  };

  fetchMilkTypes = async () => {
    try {
      const res = await userService.getMilkTypes();
      console.log("Getting milk types:", res.data.milk_types);

      if (res.data && res.data.milk_types) {
        this.setState({ milkTypes: res.data.milk_types });
      }
    } catch (error) {
      console.error("Error in fetching milk types:", error);
    }
  };

  
  
  handleSummaryClick = async () => {
    try {
      // Start the API call to get summary data
      const response = await userService.getSummaryData()

      if (response && response.data && response.data.data) {
        // setSummaryData(response.data.data);
        this.setState({ summaryData: response.data.data });
        // setModalOpen(true);  // Open the modal after the data is fetched
        this.setState({ isModalOpen: true });
      } else {
        console.error("Invalid response data", response);
      }
    } catch (error) {
      console.error("Error fetching summary data:", error);
    }
  };
  
  handlePageFirst =()=>{
    const { pageNo } = this.state;
    if (pageNo > 1) {
      this.setState({ pageNo: 1 }, () => {
        this.fetchingData();
      });
    }
    


  }

  handleFirstPage = () => {
    this.setState({ pageNo: 1 }, this.fetchingData);
  };

  handlePageLeft = () => {
    const { pageNo } = this.state;
    if (pageNo > 1) {
      this.setState({ pageNo: pageNo - 1 }, () => {
        this.fetchingData();
      });
    }
  };

  // Navigate to the next page
  handlePageRight = () => {
    const { pageNo, totalPages } = this.state;
    if (pageNo < totalPages) {
      this.setState(
        (prevState) => ({ pageNo: prevState.pageNo + 1 }),
        () => {
          this.fetchingData(); // Trigger fetchingData after state update
        }
      );
    }
  };

  // Navigate to the last page

  // Navigate to a specific page
  handlePageClick = (page) => {
    if (page !== this.state.pageNo && !this.state.loading) {
      this.setState({ pageNo: page }, () => {
        this.fetchingData();
      });
    }
  };

  handleLastPage = () => {
    const { totalPages } = this.state;
    this.setState({ pageNo: totalPages }, this.fetchingData);
  };

  handleFarmerNameChange = (event) => {
    const value = event.target.value;  


      this.setState({ tempSelectedFarmer: value }); 


  }



  handleCenterChange = (event) => {
    const selectedCenter = event.target.value;
    if (selectedCenter !== this.state.tempSelectedCenter) {
      this.setState({ tempSelectedCenter: selectedCenter });
    }
  };
  
  handleMilkTypeChange = (event) => {
    this.setState({ tempSelectedMilkType: event.target.value });
  };

  handleCollectionFromDateChange = (event) => {
    const selectedDate = event.target.value;
    this.setState({ fromDate: selectedDate }); 
  };
  
  handleCollectionToDateChange = (event) => {
    const selectedDate = event.target.value;
    this.setState({ toDate: selectedDate }); 
  };

  handlePageSizeChange = (event) => {
    const newPageSize = parseInt(event.target.value, 10);
    this.setState({ pageSize: newPageSize, pageNo: 1 }, this.fetchingData); 
  };

  navigateMainBoard = () => {
    const { isParentLogged, isManagerLogged } = this.state;
    if (isParentLogged || isManagerLogged) {
      this.props.history.push("/fpohomeData");
    } else {
      this.props.history.push("/dashboard");
    }
  };

  handleFileUpload = (event) => {
    this.setState({ file: event.target.files[0] });
  };

  handleUploadSubmit = async () => {
    this.setState({ file: null, fileName: "" });
    const { file } = this.state;

    console.log("Uploaded File:", file);

    if (!file) {
      alert("Please upload a file before submitting.");
      return;
    }

    // Fixed values for API call
    const fpoId = "689";
    const centreId = "1";
    const milkTypeId = "2";

    const formData = new FormData();
    formData.append("fpo_id", fpoId);
    formData.append("centre_id", centreId);
    formData.append("milk_type_id", milkTypeId);
    formData.append("sheet", file);

    try {
      const response = await UserService.uploadFile(formData);
      console.log("Upload Response:", response);
      alert("File uploaded successfully!");
    } catch (error) {
      console.error("Error uploading file:", error);
      alert("Failed to upload file.");
    }

    // After successful submission, reset the file input and file name
    this.setState({ fileName: "", file: null });

    // Reset the file input
    if (this.fileInput) {
      this.fileInput.value = null; // Reset the input value to allow re-selection
    }
  };

  handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      this.setState({ file, fileName: file.name }); // Set file and its name
    }
  };

  handleExport = async () => {
    const { pageSize, totalPages } = this.state;
  
   
    if (totalPages === 0 || pageSize === 0) {
      alert("No data available to export.");
      return;
    }
  
    let allData = [];
  
  
    for (let pageNo = 1; pageNo <= totalPages; pageNo++) {
      try {
       
        const response = await userService.getCollectionData(
          pageNo, pageSize, 
          this.state.tempSelectedCenter || "all", 
          this.state.tempSelectedFarmer || "all", 
          this.state.tempSelectedMilkType || "all", 
          this.state.fromDate, 
          this.state.toDate
        );
        
        const collectionData = response.data.collection_data;
        allData = [...allData, ...collectionData];  // Combine the current page data with all previous pages
  
      } catch (error) {
        console.error("Error fetching data for page " + pageNo, error);
      }
    }
  
    if (!allData || allData.length === 0) {
      alert("No data available to export.");
      return;
    }
  
    const csvData = allData.map((row) => ({
      "Center Name": row.collection_centre_name,
      "Farmer Name": row.farmer_first_name,
      "Farmer Number": row.farmer_phone,
      "Farmer Village": row.farmer_village,
      "Collection Date": row.created_at,
      "Milk Type": row.milk_type_name,
      "Can Number": row.can_no,
      "Quantity (lit)": row.quantity,
      "Rate (Rs)": row.rate,
      "Fat (%)": row.fat,
      "SNF (%)": row.snf,
      "AWM (%)":row.awm,
      "CLR ": row.clr,
      "Commission Percent": row.commision_percentage,
      "Commission Amount":row.commision,
      "Total Amount (Rs)": row.total_amount,
      
    }));
  
    const csv = Papa.unparse(csvData);
  
    // Create a Blob for CSV download
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
  
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "milk_collection_data.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };
  
  render() {
    const {
      loading,
      pageNo,
      pageSize,
      totalPages,
      totalAmount,
      centerNames,
      tempSelectedCenter,
      tempSelectedFarmer,
      tempSelectedMilkType,
      milkTypes,
      collectionFromDate,
      collectionToDate,
      filteredData,
      farmerName,
      formattedDate,
      isModalOpen,
      summaryData,
      bmcCentre,
    } = this.state;
    // const filteredData = this.getFilteredData();
    const columns = [
      // {
      //   title: "S.No",
      //   render: (rowData) => filteredData.indexOf(rowData) + 1,
      //   width: 50,
      //   cellStyle: { textAlign: "center" },
      // },
      // { title: "FPO Name", field: "fpo_name", width: 150 },
      { title: "Center Name", field: "collection_centre_name", width: 150 },
      {
        title: "Farmer Name",
        field: "farmer_name",
        render: (rowData) =>
          `${rowData.farmer_first_name} ${rowData.farmer_last_name}`,
        cellStyle: { width: "150px" },
      },
      {
        title: "Farmer Mobile Number",
        field: "farmer_phone",
        width: 150

      },
      {
        title: "Farmer Village",
        field:  "farmer_village",
        width: 150

      },


      { title: "Collection-date", field: "created_at", width: 150 },
      {
        title: "Milk type",
        field: "milk_type_name",
        filtering: false,
        width: 150,
      },
      { title: "Can Number", field: "can_no", filtering: false, width: 150 },
      {
        title: "Quantity (lit)",
        field: "quantity",
        filtering: false,
        width: 150,
      },
      { title: "Rate (₹)", field: "rate", filtering: false, width: 150 },
      { title: "Fat (%)", field: "fat", filtering: false, width: 150 },
      { title: "SNF (%)", field: "snf", filtering: false, width: 150 },
      {title: "AWM (%)", field : "awm" , filtering : false, width: 150},
      {title: "CLR", field : "clr" , filtering : false, width: 150},

      {title:"Commission Percent "  , field: "commision_percentage", 
        render: (rowData)=>{                   
        return rowData.commision_percentage==null ? "0.00" : rowData.commision_percentage;
       }, filtering: false, width:150 },
      
      {title:"Commission Amount " , field: "commision" , 
        render: (rowData)=>{                   
          return rowData.commision ==null ? "0.00" : rowData.commision;
         },
        
        filtering: false, width:150},
      {
        title: "Total Amount (₹)",
        field: "total_amount",
        filtering: false,
        width: 150,
      },
    ];

    return (
      <section className="mainWebContentSection">
        <Fragment>
          <div>
            {isModalOpen && (
              <SummaryModal
                data={summaryData}
                onClose={() => this.setState({ isModalOpen: false })}
              />
            )}
          </div>
          <div className="breadcrumb pageBreadCrumbHolder landHoldingBreadCrumbWrap">
            <a
              href="#"
              className="breadcrumb-item pageBreadCrumbItem"
              onClick={() => this.navigateMainBoard()}
            >
              <FontAwesomeIcon
                icon={faHome}
                className="dvaraBrownText breadcrumb-separator pageBreadCrumbItem"
                style={{ fontSize: "0.7rem" }}
              />
              &nbsp;Dashboard
            </a>
          </div>

          <div className="landholdingHeader wrap">
            <Row style={{ marginTop: "20px", marginBottom: "20px" }}>
              <Col lg="12" md="12" sm="12">
                <div className="PageHeading padding15">
                  <Row>
                    <Col md="12" className="headingContainer">
                      <h4 className="farmerListHeading dvaraBrownText">
                        <i className="milkIcon" title="Dairy Commodity"></i>
                        Milk Collection Data
                      </h4>
                    </Col>
                  </Row>
                </div>
              </Col>

              <Row style={{ display: "flex", flexWrap: "nowrap" }}>
                <Col style={{ marginLeft: "400px" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    component={Link}
                    to="/dispatch"
                    style={{ textTransform: "none", color: "white" }}
                  >
                    Dispatch&nbsp;&nbsp;
                    <FontAwesomeIcon icon={faForward} />
                  </Button>
                </Col>

                <Col xs="auto">
                  <Button
                    className="exportDataButton"
                    variant="contained"
                    size="sm"
                    onClick={this.handleExport}
                    style={{ textTransform: "none", marginBottom: "10px" }}
                  >
                    <FontAwesomeIcon
                      icon={faDownload}
                      className="dvaraBrownText"
                    />
                    &nbsp;&nbsp;Export Data
                  </Button>
                </Col>

                <Col xs={12} sm={2} className="mb-2">
                  <Button
                    variant="contained"
                    onClick={this.handleSummaryClick}
                    // disable={loading ? true : false}
                    style={{
                      textTransform: "none", width: '180px'
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faListAlt}
                      className="dvaraBrownText"
                    />
                    &nbsp;&nbsp;Summary Report
                  </Button>
                </Col>

                <Col xs="auto">
                  <input
                    type="file"
                    accept=".xlsx"
                    onChange={this.handleFileChange} // Handle file selection
                    style={{ display: "none" }}
                    ref={(input) => (this.fileInput = input)}
                  />

                  <Button
                    variant="contained"
                    size="sm"
                    style={{ textTransform: "none", marginBottom: "10px" }}
                    onClick={() => this.fileInput.click()} // Trigger file input on button click
                  >
                    <FontAwesomeIcon
                      icon={faUpload}
                      className="dvaraBrownText"
                    />
                    &nbsp;&nbsp;Upload Rate Excel
                  </Button>
                  {this.state.fileName && ( // Display file name if a file is selected
                    <div
                      style={{
                        marginTop: "10px",
                        fontSize: "14px",
                        color: "grey",
                      }}
                    >
                      Uploaded file: {this.state.fileName}
                    </div>
                  )}
                </Col>
                <Col xs="auto">
                  <Button
                    variant="contained"
                    color="primary"
                    size="sm"
                    style={{
                      marginLeft: "10px",
                      textTransform: "none",
                    }}
                    onClick={this.handleUploadSubmit}
                  >
                    Submit
                  </Button>
                </Col>
                
              </Row>
            </Row>
            <Row style={{ marginTop: "5px", marginLeft: "15px" }}>
              <p
                className="dvaraBrownText"
                style={{ margin: "0px 0px 10px 30px", fontSize: "1.3rem" }}
              >
                <strong>BMC Centre:</strong> {bmcCentre}
              </p>

              <Col className="seasonDropdown" xs={12}>
                <Row className="g-3">
                  <Col xs={12} sm={2} className="mb-2">
                    <Form.Group>
                      <Form.Label style={{ fontSize: "15px", color: "brown" }}>
                        Center Name
                      </Form.Label>
                      <Form.Control
                        size="sm"
                        as="select"
                        style={{ border: "1px solid grey", color: "brown" }}
                        value={tempSelectedCenter}
                        onChange={this.handleCenterChange}
                      >
                        <option value="ALL">ALL</option>
                        {centerNames.map((center) => (
                          <option key={center.id} value={center.id}>
                            {center.collection_centre_name}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>

                  <Col xs={12} sm={2} className="mb-2">
                    <Form.Group>
                      <Form.Label style={{ fontSize: "15px", color: "brown" }}>
                        Farmer Details
                      </Form.Label>
                      <Form.Control
                        size="sm"
                        type="text"
                        placeholder="Farmer Name/Village/Number"
                        style={{ border: "1px solid grey", color: "brown" }}
                        value={tempSelectedFarmer}
                        onChange={this.handleFarmerNameChange}
                      />
                    </Form.Group>
                  </Col>

                  <Col xs={12} sm={2} className="mb-2">
                    <Form.Group>
                      <Form.Label style={{ fontSize: "15px", color: "brown" }}>
                        Milk Type
                      </Form.Label>
                      <Form.Control
                        size="sm"
                        as="select"
                        style={{ border: "1px solid grey", color: "brown" }}
                        value={tempSelectedMilkType}
                        onChange={this.handleMilkTypeChange}

                      >
                        <option value="ALL">ALL</option>
                        {milkTypes.map((type) => (
                          <option key={type.id} value={type.id}>
                            {type.milk_type}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>

                  <Col xs={12} sm={2} className="mb-2">
                    <Form.Group>
                      <Form.Label style={{ fontSize: "15px", color: "brown" }}>
                        Collection-Date(from)
                      </Form.Label>
                      <Form.Control
                        size="sm"
                        type="date"
                        style={{ border: "1px solid grey", color: "brown" }}
                        value={this.state.fromDate}
                        onChange={this.handleCollectionFromDateChange}
                      />
                    </Form.Group>
                  </Col>

                  <Col xs={12} sm={2} className="mb-2">
                    <Form.Group>
                      <Form.Label style={{ fontSize: "15px", color: "brown" }}>
                        Collection-Date(to)
                      </Form.Label>
                      <Form.Control
                        size="sm"
                        type="date"
                        style={{ border: "1px solid grey", color: "brown" }}
                        value={this.state.toDate}
                        onChange={this.handleCollectionToDateChange}
                      />
                    </Form.Group>
                  </Col>


                  

                  {/* <Col xs={12} sm={2} className="mb-2">
                    <Button
                      style={{ width: "150px", marginTop: "25px" }}
                      variant="contained"
                      color="primary"
                      onClick={this.fetchingData}
                    >
                      Apply Filter
                    </Button>
                  </Col> */}
                </Row>
              </Col>
            </Row>
            {loading ? (
              <div className="loaderWrap">
                <span
                  style={{
                    width: "70px",
                    height: "70px",
                    marginLeft: "600px",
                  }}
                  className="spinner spinner-border spinner-border-lg"
                ></span>
              </div>
            ) : (
              <MaterialTable
                icons={tableIcons}
                title=""
                style={{ marginLeft: "30px" }}
                data={filteredData}
                columns={columns}
                options={{
                  toolbar: false,
                  maxBodyHeight: '500',
                  actionsColumnIndex: -1,
                  doubleHorizontalScroll: true,
                  pageSize: 10,
                  exportButton: false,
                  headerStyle: {
                    backgroundColor: "#A3C614",
                    color: "#fff",
                    fontSize: "1.2rem",
                    fontFamily: "barlow_reg",
                    fontWeight: "bold",
                    position: "sticky",
                    top: 0,
                    overflow: "hidden",
                    left: 0,
                  },
                  rowStyle: {
                    backgroundColor: "#f1f1f1",
                    borderBottom: "2px solid #e2e2e2",
                    fontSize: "0.9rem",
                  },
                  filtering: false,
                  paging: false,
                  loading: true,
                }}
              />
            )}
          </div>
          <div>

          <div className="dvaraBrownText total" style={{marginTop:"-54px", marginRight:"162px", fontSize:"large"}}>
            <b>Total Amount(page wise): &nbsp;{totalAmount}</b>
          </div>

          <div className="pagination" style={{padding: "3px 3px ", marginRight:"50px", marginTop:"100px"}}>
            <div>
            <label htmlFor="pageSize">Page Size:</label>
          <select
            id="pageSize"
            value={pageSize}
            onChange={this.handlePageSizeChange}

            
          >
            <option  key ="10" value={10}>10</option>
            <option key="15" value={15}>15</option>
            <option key="20" value={20}>20</option>
            <option key="25" value={25}>25</option>
          </select>

            </div>

            <button onClick={this.handlePageFirst} disabled={pageNo < 1}>
               First
            </button>

         
            <button onClick={this.handlePageLeft} disabled={pageNo <= 1}>
               Prev
            </button>

            <button>
              <span className="pagination-2" style={{fontWeight:"bold"}}>
                Page {pageNo} of {totalPages}
              </span>
            </button>

            {/* Display page numbers */}
            {this.getPageNumbers().map((page) => (
              <button
                key={page}
                onClick={() => this.handlePageClick(page)}
                className={page === pageNo ? "active" : ""}
              >
                {page}
              </button>
            ))}

            <button
              onClick={this.handlePageRight}
              disabled={pageNo >= totalPages || loading}
            >
              Next 
            </button>

            <button onClick={this.handleLastPage} disabled={pageNo >= totalPages}>
                Last Page 
            </button>
          </div>



          </div>
          

        
        </Fragment>
      </section>
    );
  }
}
