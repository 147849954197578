import React from "react";

const SummaryModal = ({ data, onClose }) => {
  if (!data) return null;

  const getUniqueValues = (array, key) => {
    return Array.from(new Set(array.map((item) => item[key]))).map((id) => {
      return array.find((item) => item[key] === id);
    });
  };

  const uniqueCollectionCentres = getUniqueValues(
    data.collection_centre_name,
    "collection_centre_id__collection_centre"
  );

  const uniqueCollectionsToday = data.collections_today;
  const uniqueInventory = data.inventory;
  const uniqueDispatchData = data.dispatches_today;
  const uniqueWastageData = data.wastage;
  const uniqueProfitData = data.profit_dict
  const uniqueagentcommision = data.agent_commision
  const uniqueTransportcost = data.total_transport_costs

  // Base array of milk types
  const milkTypes = ["Cow", "Buffalo", "Mixed"];

  const modalStyles = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 9999,
  };
 

  const modalContentStyles = {
    backgroundColor: "rgb(255, 255, 255)",
    borderRadius: "8px",
    maxWidth: "90%",
    width: "90%",
    textAlign: "center",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
  };

  const headerStyles = {
    backgroundColor: "rgb(163, 198, 20)",
    color: "rgb(255, 255, 255)",
    padding: "20px",
    fontSize: "22px",
    textAlign: "left",
    borderRadius: "8px 8px 0 0",
  };

  const tableStyles = {
    width: "100%",
    borderCollapse: "collapse",
    marginTop: "10px",
  };

  const tableHeaderStyles = {
    backgroundColor: "rgb(163, 198, 20)",
    color: "rgb(255, 255, 255)",
    textAlign: "left",
    fontSize: "16px",  // Reduced font size
  };
  
  const tableCellStyles = {
    border: "1px solid #ddd",
    padding: "6px",  // Reduced padding
    textAlign: "center",
    fontSize: "16px",  // Reduced font size
    padding: "8px", // Reduced padding
  };
  

  const closeButtonStyles = {
    position: "absolute",
    top: "10px",
    right: "10px",
    fontSize: "20px",
    backgroundColor: "transparent",
    border: "none",
    color: "rgb(255, 255, 255)",
    cursor: "pointer",
  };

  // Get current date in dd/mm/yyyy format
  const formatDate = () => {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, "0");
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = today.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const currentDate = formatDate();

  return (
    <div className="modal" style={modalStyles}>
      <div className="modal-content" style={modalContentStyles}>
        <button
          className="close-button"
          onClick={onClose}
          style={closeButtonStyles}
        >
          &times;
        </button>
        <div style={headerStyles}>
          <p>
            <strong>Date:</strong> {currentDate}
          </p>
          <p className="dvaraBrownText">
            <strong>Centre Name:</strong>{" "}
            {uniqueCollectionCentres
              .map((centre) => centre.collection_centre_id__collection_centre)
              .join(", ")}
          </p>

          <p className="dvaraBrownText">
            <strong>FPO Name:</strong> Dvara Trust Farmers Producer Co. Ltd.
          </p>
        </div>

        <table style={tableStyles}>
          <thead>
            <tr style={tableHeaderStyles}>
              <th style={tableCellStyles}className="dvaraBrownText">Milk Type</th>
              <th style={tableCellStyles} colSpan={3}className="dvaraBrownText">
                Collections
              </th>
              <th style={tableCellStyles} colSpan={3}className="dvaraBrownText">
                Dispatched
              </th>
              <th style={tableCellStyles} className="dvaraBrownText" colSpan={3}>Inventory </th>
              <th style={tableCellStyles} className="dvaraBrownText" colSpan={3}>Wastage</th>
              <th  style={tableCellStyles}className="dvaraBrownText">Agent Commission</th>
              <th  style={tableCellStyles}className="dvaraBrownText">Transport cost</th>
              <th  style={tableCellStyles}className="dvaraBrownText ">Profit/loss &nbsp;<span className='Profithover'>ⓘ</span></th>
            </tr>
            <tr style={tableHeaderStyles}>
              <th style={tableCellStyles}></th>
              <th style={tableCellStyles}>Qty</th>
              <th style={tableCellStyles}>Rate</th>
              <th style={tableCellStyles}>Amount</th>
              <th style={tableCellStyles}>Qty</th>
              <th style={tableCellStyles}>Rate</th>
              <th style={tableCellStyles}>Amount</th>
              <th style={tableCellStyles}>Qty</th>
              <th style={tableCellStyles}>Rate</th>
              <th style={tableCellStyles}>Amount</th>
              <th style={tableCellStyles}>Qty</th>
              <th style={tableCellStyles}>Rate</th>
              <th style={tableCellStyles}>Amount</th>
              <th style={tableCellStyles}>Amount</th>
              <th style={tableCellStyles}>Amount</th>
              <th style={tableCellStyles}>Amount</th>
              
            </tr>
          </thead>
          <tbody>
            {milkTypes.map((milkType, index) => {
              const collectionItem = uniqueCollectionsToday.find(
                (item) => item.milk_type === milkType
              ) || { total_quantity_collected: 0 , rate:0,total_amount:0};

              const inventoryItem = uniqueInventory.find(
                (inv) => inv.milk_type === milkType
              ) || { inventory_quantity: 0,rate:0,total_amount:0 };

              const dispatchItem = uniqueDispatchData.find(
                (dispatch) => dispatch.milk_type === milkType
              ) || { total_quantity_dispatched: 0 ,rate:0,total_amount:0};
              
              const wastageItem = uniqueWastageData.find(
                (wastage) => wastage.milk_type === milkType
              ) || { rate: 0, quantity: 0 ,total_amount:0};
              
              const ProfitItem = uniqueProfitData.find(
                (Profit) => Profit.milk_type === milkType
              ) || { profit:0};
              
              const agentCommisionItem = uniqueagentcommision.find(
                (commision) => commision.milk_type === milkType
              ) || { agent_commision:0};
              
              const TransportcostItem = uniqueTransportcost.find(
                (transportcost) => transportcost.milk_type === milkType
              ) || { total_transport_cost:0};

              return (
                <tr key={index}>
                  {/* Milk Type */}
                  <td style={tableCellStyles}>{milkType}</td>
                  {/* Collection Items */}
                  <td style={tableCellStyles}>{collectionItem.total_quantity_collected}</td>
                  <td style={tableCellStyles}>
                    {collectionItem.rate}
                  </td>
                  <td style={tableCellStyles}>
                    {collectionItem.total_amount}
                  </td>
                  {/* Dispatched */}
                  <td style={tableCellStyles}>
                    {dispatchItem.total_quantity_dispatched}
                  </td>
                  <td style={tableCellStyles}>
                    {dispatchItem.rate}
                  </td>
                  <td style={tableCellStyles}>
                    {dispatchItem.total_amount}
                  </td>
                  {/* Inventory */}
                  <td style={tableCellStyles}>{inventoryItem.inventory_quantity}</td>
                  <td style={tableCellStyles}>
                    {inventoryItem.rate}
                  </td>
                  <td style={tableCellStyles}>
                    {inventoryItem.total_amount}
                  </td>
                  {/* Wastage */}
                  <td style={tableCellStyles}>
                    {wastageItem.quantity}
                  </td>
                  <td style={tableCellStyles}>
                    {wastageItem.rate}
                  </td>
                  <td style={tableCellStyles}>
                    {wastageItem.total_amount}
                  </td>
                  {/* Agent Commission */}
                  <td style={tableCellStyles}>
                    {agentCommisionItem.agent_commision}
                  </td>
                  {/* Transport Cost */}
                  <td style={tableCellStyles}>
                    {TransportcostItem.total_transport_cost}
                  </td>
                  {/* Profit */}
                  <td style={tableCellStyles}>
                    {ProfitItem.profit}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SummaryModal;