import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import MaterialTable from "material-table";
import tableIcons from "./icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faDownload,
  faUpload,
  faBackward,
  faListAlt,
} from "@fortawesome/free-solid-svg-icons";
import userService from "../services/user.service";
import { Row, Col, Form } from "react-bootstrap";
import { Button } from "@material-ui/core";
import "../assets/css/dispatch.css";
import Papa from "papaparse";
import { Tooltip } from "@material-ui/core";
import axios from "axios";
import SummaryModal from "./components/summaryModal";
 
const Dispatch = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const[totalAmount,setTotalAmount]=useState([])
  const [filteredData, setFilteredData] = useState([]);
  const [centerNames, setCenterNames] = useState([]);
  const [buyerNames, setBuyerNames] = useState([]);
  const [milkTypes, setMilkTypes] = useState([]); // New state for milk types
  const [tempSelectedCenter, setTempSelectedCenter] = useState("");
  const [tempSelectedBuyer, setTempSelectedBuyer] = useState("");
 
  const [isModalOpen, setModalOpen] = useState(false);
  const [summaryData, setSummaryData] = useState(null);
 
  const [fromDate, setFromDate] = useState(() => {
    const today = new Date();
    return today.toISOString().split("T")[0]; // Format as YYYY-MM-DD
  });
  const [toDate, setToDate] = useState(() => {
    const today = new Date();
    return today.toISOString().split("T")[0]; // Format as YYYY-MM-DD
  });
 
  const [tempSelectedMilkType, setTempSelectedMilkType] = useState("");
  const [currentDate, setCurrentDate] = useState("");
  console.log("ccc", currentDate);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
 
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const day = String(today.getDate()).padStart(2, "0");
  // setCurrentDate(`${year}-${month}-${day}`);
  const startDate = `${year}-${month}-${day}`;
  const endDate = `${year}-${month}-${day}`;
  console.log(startDate, "dateofdate", endDate);
 
 
  const [bmcCentre, setBmcCentre] = useState('');
 
 
  // Dynamically calculate the visible page numbers
  const getPageNumbers = () => {
    let startPage = Math.floor((pageNo - 1) / 5) * 5 + 1;
    let endPage = Math.min(startPage + 4, totalPages);
    return Array.from(
      { length: endPage - startPage + 1 },
      (_, index) => startPage + index
    );
  };
 
  // useEffect(() => {
  //   const today = new Date();
  //   const year = today.getFullYear();
  //   const month = String(today.getMonth() + 1).padStart(2, "0");
  //   const day = String(today.getDate()).padStart(2, "0");
  //   setCurrentDate(`${year}-${month}-${day}`);
  // }, []);
 
  const fetchingData = async (page) => {
    setLoading(true);
    try {
      const response = await userService.getDispatchData(
        pageNo,
        pageSize,
        tempSelectedCenter ? tempSelectedCenter : "all",
        tempSelectedBuyer ? tempSelectedBuyer : "all",
        tempSelectedMilkType ? tempSelectedMilkType : "all",
        fromDate ? fromDate : startDate,
        toDate ? toDate : endDate
      );
      console.log("Dispatch Data:", response.data);
 
      const dispatchData = response.data.dispatch_data;
      const totalRecords = response.data.total_records;
      console.log("bhavani", totalRecords);
      const totalPagescalculated = Math.ceil(totalRecords / pageSize);
      console.log("bhavani pages", totalPagescalculated);
 
 
      setLoading(false);
      setData(dispatchData);
      setFilteredData(dispatchData);
      setTotalPages(totalPagescalculated);
      setTotalAmount(response.data.total_amount_sum)


 
 
      if (pageNo > totalPagescalculated) {
        setPageNo(1); // / Set to the last page.
      }
 
      // Set milk types from API data
    } catch (error) {
      setLoading(false);
      console.error("Error in fetching dispatch data", error);
    }
  };
 
  useEffect(() => {
    const fetchCenters = async () => {
      try {
        const res = await userService.getCenters();
        console.log("getting centers", res.data.collection_centres);
 
        if (res.data && res.data.collection_centres) {
          setCenterNames(res.data.collection_centres);
 
          // Extracting the first BMC name
          const firstBmcName = res.data.collection_centres[0]?.bmcs?.[0]?.bmc_name || "No BMC Available";
          setBmcCentre(firstBmcName);
        }
      } catch (error) {
        console.log("error in fetching centers", error);
      }
    };
 
    fetchCenters();
  }, []);
 
  console.log("bmcname", bmcCentre);
 
 
  useEffect(() => {
    const fetchBuyers = async () => {
      try {
        const res = await userService.getBuyers();
        console.log("getting Buyers", res.data);
        if (res.data && res.data.buyers_list) {
          setBuyerNames(res.data.buyers_list);
        }
      } catch (error) {
        console.log("error in fetching buyers");
      }
    };
 
    fetchBuyers();
  }, []);
 
  useEffect(() => {
    const fetchMilkTypes = async () => {
      try {
        const res = await userService.getMilkTypes();
        console.log("getting milk", res.data.milk_types);
        if (res.data && res.data.milk_types) {
          setMilkTypes(res.data.milk_types);
        }
      } catch (error) {
        console.log("error in fetching milk types");
      }
    };
    fetchMilkTypes();
  }, []);
 
  useEffect(() => {
    fetchingData(pageNo);
 
  }, [pageNo, pageSize, fromDate, toDate, tempSelectedCenter, tempSelectedBuyer, tempSelectedMilkType])
 
 
  const handleSummaryClick = async () => {
    try {
      // Start the API call to get summary data
      const response = await userService.getProfitData()
   
 
      if (response && response.data && response.data.data) {
        setSummaryData(response.data.data);
        setModalOpen(true);  // Open the modal after the data is fetched
      } else {
        console.error("Invalid response data", response);
      }
    } catch (error) {
      console.error("Error fetching summary data:", error);
    }
  };
 
  // Track the modal state change using useEffect
  useEffect(() => {
    console.log("isModalOpen changed:", isModalOpen); // This will log when the state changes
  }, [isModalOpen]);
 
 
  const handleFirstPage = () => {
    setPageNo(1); // Set the page to the first page
    fetchingData(1); // Fetch data for the first page
  };
 
 
 
  const handlePageLeft = () => {
    if (pageNo > 1) {
      const newPageNo = pageNo - 1;
      setPageNo(newPageNo);
      fetchingData(newPageNo);
    }
  };
 
  const handlePageRight = () => {
    if (pageNo < totalPages) {
      const newPageNo = pageNo + 1;
      setPageNo(newPageNo);
      fetchingData(newPageNo);
    }
  };
 
 
  const handlePageClick = (page) => {
    setPageNo(page);
    fetchingData(page);
  };
 
  const handleLastPage = () => {
    setPageNo(totalPages); // Set the page to the last page
    fetchingData(totalPages); // Fetch data for the last page
  };
 
 
  const handleFromDate = (e) => {
    setFromDate(e.target.value);
  };
 
  const handleToDate = (e) => {
    setToDate(e.target.value);
  };
  const handleMilkType = (e) => {
    setTempSelectedMilkType(e.target.value);
  };
 
  const handleBuyer = (e) => {
    setTempSelectedBuyer(e.target.value);
  };
 
 
  const handleCenter = (e) => {
    setTempSelectedCenter(e.target.value);
  };
  const handlePageSizeChange = (e) => {
    setPageSize(e.target.value);
  };
 
 
 
 
 
  // Function to fetch all data based on the current filters (without pagination)
  const fetchAllFilteredData = async () => {
    setLoading(true); // Show loading spinner while fetching data
 
    try {
      // Fetch all data with the current filters but bypass pagination
      const response = await userService.getDispatchData(
        1, // Page 1 for backend call (we don't care about pagination for export)
        9999, // Large page size to get all the data
        tempSelectedCenter ? tempSelectedCenter : "all",
        tempSelectedBuyer ? tempSelectedBuyer : "all",
        tempSelectedMilkType ? tempSelectedMilkType : "all",
        fromDate ? fromDate : startDate,
        toDate ? toDate : endDate
      );
 
      // Return the filtered data without updating the current page data
      const allFilteredData = response.data.dispatch_data;
      setLoading(false);
      return allFilteredData;  // Return all filtered data for export
    } catch (error) {
      setLoading(false);
      console.error("Error fetching all filtered dispatch data", error);
      return []; // Return an empty array in case of error
    }
  };
 
 
  // Update the export function
  const handleExportData = async () => {
    const allFilteredData = await fetchAllFilteredData();  // Fetch all filtered data for export
 
    if (allFilteredData.length === 0) {
      alert("No data available to export");  // Handle case when no data is returned
      return;
    }
 
    // Use Papa Parse to convert the data to CSV format
    const csv = Papa.unparse(allFilteredData);
 
    // Create a link to trigger the file download
    const link = document.createElement("a");
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
 
    link.href = URL.createObjectURL(blob);
    link.download = "dispatch_data.csv";  // Set the name for the CSV file
 
    link.click();  // Trigger the download
  };
 
 
  const columns = [
    // {
    //   title: "S.No",
    //   render: (rowData) => filteredData.indexOf(rowData) + 1,
    //   width: 50,
    //   cellStyle: { textAlign: "center" },
    // },
    // { title: "FPO name", field: "fpo_name", width: 150 },
    { title: "Center name", field: "collection_centre_name", width: 150 },
    { title: "Buyer name", field: "buyer_name", width: 150 },
    {title: "Buyer-contact Person" , field :"contact_person_name",width: 150 },
    {title: "Buyer -GST", field: "gst" , width: 150},
    { title: "Vehicle number", field: "vehicle_no", width: 150 },
    { title: "Driver name", field: "driver_name", width: 150 },
    { title: "Driver mobile no.", field: "driver_number", width: 150 },
    { title: "Dispatch-date", field: "dispatch_date", width: 150 },
    { title: "Milk type", field: "milk_type_name", width: 150 },
    // { title: "Can number", field: "can_no", width: 150 },
    { title: "Quantity (lit)", field: "quantity", width: 150 },
    { title: "Rate (₹)", field: "rate", width: 150 },
    { title: "Fat (%)", field: "fat", width: 150 },
    { title: "SNF (%)", field: "snf", width: 150 },
    {title: "Transport cost", field: "transport_cost",width: 150},
    { title: "Total amount (₹)", field: "total_amount", width: 150 },
  ];



 
 
 
  return (
    <section className="mainWebContentSection">
      <Fragment>
        <div>
          {isModalOpen && (
            <SummaryModal
              data={summaryData}
              onClose={() => setModalOpen(false)}
            />
          )}
        </div>
        <div className="breadcrumb pageBreadCrumbHolder landHoldingBreadCrumbWrap">
          <Link to="/dashboard" className="breadcrumb-item pageBreadCrumbItem">
            <FontAwesomeIcon
              icon={faHome}
              className="dvaraBrownText breadcrumb-separator pageBreadCrumbItem"
              style={{ fontSize: "0.7rem" }}
            />
            &nbsp;Dashboard
          </Link>
        </div>
 
        <div className="landholdingHeader wrap">
          <Row style={{ marginTop: "20px", marginBottom: "20px" }}>
            <Col lg="12" md="12" sm="6">
              <div className="PageHeading padding15">
                <Row>
                  <Col className="dispatchContainer">
                    <i className="dispatchIcon" title="dispatch data"></i>
                    <h4 className="farmerListHeading dvaraBrownText">
                      Dispatch Data
                    </h4>
                  </Col>
                </Row>
              </div>
            </Col>
            <Row>
              <Col style={{ marginLeft: "500px", marginBottom: "20px" }}>
                <Button
                  variant="contained"
                  color="primary"
                  component={Link}
                  to="/fpoMilk"
                  style={{ textTransform: "none", color: "white" }}
                >
                  <FontAwesomeIcon
                    icon={faBackward}
                  />
                  &nbsp;&nbsp;Milk Collection
                </Button>
              </Col>
 
              <Col xs="auto">
                <Button
                  className="exportDataButton"
                  variant="contained"
                  size="sm"
                  style={{ textTransform: "none", marginBottom: "10px" }}
                  onClick={handleExportData}
                >
                  <FontAwesomeIcon
                    icon={faDownload}
                    className="dvaraBrownText"
                  />
                  &nbsp;&nbsp;Export Data
                </Button>
              </Col>
              <Col xs={12} sm={2} className="mb-2">
                <Button
                  variant="contained"
                  onClick={handleSummaryClick}
                  // disable={loading ? true : false}
                  style={{ textTransform: "none", width: "200px" }}
                >
 
                  <FontAwesomeIcon
                    icon={faListAlt}
                    className="dvaraBrownText"
                  />
                  &nbsp;&nbsp;Profit/Loss Summary
                </Button>
              </Col>
            </Row>
          </Row>
 
          <Row style={{ marginTop: "25px", marginLeft: "10px" }}>
            <p className="dvaraBrownText" style={{ margin: '-20px 0px 10px 30px', fontSize: '1.3rem' }}><strong>BMC Centre:</strong> {bmcCentre}</p>
            <Col className="seasonDropdown" xs={12}>
              <Row className="g-3">
                {" "}
                {/* g-3 adds spacing between columns */}
                <Col xs={12} sm={2} className="mb-2">
                  <Form.Group>
                    <Form.Label style={{ fontSize: "15px", color: "brown" }}>
                      Center Name
                    </Form.Label>
                    <Form.Control
                      size="sm"
                      as="select"
                      value={tempSelectedCenter}
                      // onChange={(e) => setTempSelectedCenter(e.target.value)}
                      onChange={handleCenter}
                      style={{ border: "1px solid grey", color: "brown" }}
                    >
                      <option value="all" selected>
                        All
                      </option>
                      {centerNames.map((center) => (
                        <option key={center.id} value={center.id}>
                          {center.collection_centre_name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col xs={12} sm={2} className="mb-2">
                  <Form.Group>
                    <Form.Label style={{ fontSize: "15px", color: "brown" }}>
                      Buyer Name
                    </Form.Label>
                    <Form.Control
                      size="sm"
                      as="select"
                      placeholder="Enter Buyer Name"
                      value={tempSelectedBuyer}
                      // onChange={(e) => setTempSelectedBuyer(e.target.value)}
                      onChange={handleBuyer}
                      style={{ border: "1px solid grey", color: "brown" }}
                    >
                      <option value="all" selected>
                        All
                      </option>
                      {buyerNames.map((buyer) => (
                        <option key={buyer.id} value={buyer.id}>
                          {buyer.buyer_name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col xs={12} sm={2} className="mb-2">
                  <Form.Group>
                    <Form.Label style={{ fontSize: "15px", color: "brown" }}>
                      Milk Type
                    </Form.Label>
                    <Form.Control
                      size="sm"
                      as="select"
                      value={tempSelectedMilkType}
                      // onChange={(e) => setTempSelectedMilkType(e.target.value)}
                      onChange={handleMilkType}
                      style={{ border: "1px solid grey", color: "brown" }}
                    >
                      <option value="all" selected>
                        All
                      </option>
                      {milkTypes.map((milkType) => (
                        <option key={milkType.id} value={milkType.id}>
                          {milkType.milk_type}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col xs={12} sm={2} className="mb-2">
                  <Form.Group>
                    <Form.Label style={{ fontSize: "15px", color: "brown" }}>
                      From Date
                    </Form.Label>
                    <Form.Control
                      size="sm"
                      type="date"
                      value={fromDate}
                      // onChange={(e) => setFromDate(e.target.value)}
                      onChange={handleFromDate}
                      style={{ border: "1px solid grey", color: "brown" }}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} sm={2} className="mb-2">
                  <Form.Group>
                    <Form.Label style={{ fontSize: "15px", color: "brown" }}>
                      To Date
                    </Form.Label>
                    <Form.Control
                      size="sm"
                      type="date"
                      value={toDate}
                      onChange={handleToDate}
                      style={{ border: "1px solid grey", color: "brown" }}
                    />
                  </Form.Group>
                </Col>
                {/* <Col xs={12} sm={2} className="mb-2">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={fetchingData}
                    disable={loading ? true : false}
                    style={{ marginTop: "25px" }}
                  >
                    Apply Filter
                  </Button>
                </Col> */}
 
              </Row>
 
 
            </Col>
          </Row>
 
          {loading ? (
            <div className="loaderWrap">
              <span
                style={{ width: "70px", height: "70px", marginLeft: "600px" }}
                className="spinner spinner-border spinner-border-lg"
              ></span>
            </div>
          ) : (
            <MaterialTable
              icons={tableIcons}
              title=""
              style={{ marginLeft: "30px" }}
              data={filteredData}
              columns={columns}
              options={{
                toolbar: false,
                maxBodyHeight: '500',
                actionsColumnIndex: -1,
                doubleHorizontalScroll: true,
                pageSize: 10,
                exportButton: false,
                headerStyle: {
                  backgroundColor: "#A3C614",
                  color: "#fff",
                  fontSize: "1.2rem",
                  fontFamily: "barlow_reg",
                  fontWeight: "bold",
                  position: "sticky",
                  top: 0,
                  overflow: "hidden",
                  left: 0,
                },
                rowStyle: {
                  backgroundColor: "#f1f1f1",
                  borderBottom: "2px solid #e2e2e2",
                  fontSize: "0.9rem",
                },
                filtering: false,
                paging: false,
                loading: true,
              }}
            />
          )}
        </div>
 
        <div className="dvaraBrownText total"  >
            <b>Total Amount(page wise): &nbsp;{totalAmount}</b>
          </div>
 
        <div className="pagination" style={{marginTop:"80px"}}>
          <div>
            <label htmlFor="pageSize">Page Size:</label>
            <select
              id="pageSize"
              value={pageSize}
              onChange={handlePageSizeChange}
 
 
            >
              <option key="10" value={10}>10</option>
              <option key="15" value={15}>15</option>
              <option key="20" value={20}>20</option>
              <option key="25" value={25}>25</option>
            </select>
 
          </div>
 
          <button onClick={handleFirstPage} disabled={pageNo < 1}>
            First
          </button>
 
 
          <button onClick={handlePageLeft} disabled={pageNo <= 1}>
            Prev
          </button>
 
          <button>
            <span className="pagination-2" style={{ fontWeight: "bold" }}>
              Page {pageNo} of {totalPages}
            </span>
          </button>
 
          {/* Display page numbers */}
          {getPageNumbers().map((page) => (
            <button
              key={page}
              onClick={() => handlePageClick(page)}
              className={page === pageNo ? "active" : ""}
            >
              {page}
            </button>
          ))}
 
          <button
            onClick={handlePageRight}
            disabled={pageNo >= totalPages || loading}
          >
            Next
          </button>
 
          <button onClick={handleLastPage} disabled={pageNo >= totalPages}>
            Last Page
          </button>
 
 
 
        </div>
 
 
 
 
 
 
 
      </Fragment>
    </section>
  );
};
 
export default Dispatch;